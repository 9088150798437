// extracted by mini-css-extract-plugin
export var dark = "y_sk";
export var darkcookie = "y_sl";
export var tintedcookie = "y_sm";
export var regularcookie = "y_sn";
export var darkbase = "y_sp";
export var tintedbase = "y_sq";
export var regularbase = "y_sr";
export var darkraw = "y_ss";
export var tintedraw = "y_st";
export var regularraw = "y_sv";
export var darkchick = "y_sw";
export var tintedchick = "y_sx";
export var regularchick = "y_sy";
export var darkherbarium = "y_sz";
export var tintedherbarium = "y_sB";
export var regularherbarium = "y_sC";
export var darkholiday = "y_sD";
export var tintedholiday = "y_sF";
export var regularholiday = "y_sG";
export var darkoffline = "y_sH";
export var tintedoffline = "y_sJ";
export var regularoffline = "y_sK";
export var darkorchid = "y_sL";
export var tintedorchid = "y_sM";
export var regularorchid = "y_sN";
export var darkpro = "y_sP";
export var tintedpro = "y_sQ";
export var regularpro = "y_sR";
export var darkrose = "y_sS";
export var tintedrose = "y_sT";
export var regularrose = "y_sV";
export var darktimes = "y_sW";
export var tintedtimes = "y_sX";
export var regulartimes = "y_sY";
export var darkwagon = "y_sZ";
export var tintedwagon = "y_s0";
export var regularwagon = "y_s1";