// extracted by mini-css-extract-plugin
export var articleWrapper = "p_p8";
export var searchResult = "p_qR p_p8";
export var line = "p_fk d_fk d_cY";
export var articleTitle = "p_qS d_cq";
export var articleText = "p_p9 d_cs";
export var authorImage = "p_ln d_ln d_b7 d_Z d_ct";
export var authorText = "p_qB d_ch d_ct";
export var textCenter = "p_dw d_dw";
export var searchWrapper = "p_qT d_bz d_bP d_dr d_cl d_Z";
export var searchInput = "p_qV d_cF d_w d_b4";
export var searchBtn = "p_qW d_b1 d_bC d_bP d_0 d_cD d_dB";
export var text = "p_qM d_cr";
export var elementWrapper = "p_my d_cl";
export var select = "p_qX d_w d_b4";
export var articleImageWrapper = "p_qr d_cs d_Z d_V";
export var articleImageWrapperIcon = "p_qs d_cs d_Z d_V";
export var articleImageWrapperColumns = "p_qY d_cs d_fh d_Z";
export var contentPadding = "p_qZ d_cS";
export var otherPadding = "p_q0 d_cM";
export var paginationWrapper = "p_q1";
export var pagination = "p_q2";
export var breakMe = "p_q3";
export var activePage = "p_q4";
export var next = "p_q5";
export var pages = "p_q6";
export var searchTitle = "p_q7 d_cr";
export var categoryTitle = "p_q8 d_cr d_Z";
export var searchText = "p_q9 d_ct";
export var bold = "p_rb";
export var field = "p_rc";
export var dateTag = "p_qj d_l d_cr d_dq";
export var icon1 = "p_qk d_cP";
export var icon2 = "p_ql d_cP d_cJ";
export var tagBtn = "p_qm d_b1 d_cD d_dp d_dB";
export var linksWrapper = "p_qF d_ch d_dw";
export var header = "p_qb d_w d_Z";
export var headerInner = "p_rd";
export var headerImage = "p_rf d_bd d_0 d_8 d_7 d_4 d_9";
export var separator = "p_qz d_cY";
export var resultImage = "p_rg";