// extracted by mini-css-extract-plugin
export var alignLeft = "x_rh d_fp d_bG d_dv";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignRight = "x_rj d_fr d_bH d_dx";
export var testimonialsContainer = "x_r6 d_dW";
export var testimonialsContainerFull = "x_r7 d_dT";
export var testimonialsMainHeader = "x_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "x_jy d_jy";
export var testimonialsComponentSmall = "x_jx d_jx";
export var testimonialsComponentsWrapper = "x_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "x_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "x_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "x_r8 d_d7";
export var colEqualHeight = "x_r9 d_bz";
export var testimonialsColumnWrapper = "x_jz d_jz d_b3";
export var testimonialsImageWrapper = "x_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "x_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "x_jr d_jr d_Z d_bz";
export var design2TextWrapper = "x_sb d_bC d_bP d_bJ";
export var design3 = "x_sc d_cD d_H";
export var imageDesign2 = "x_sd";
export var SmallSmall = "x_sf z_sf z_s2 z_tp";
export var SmallNormal = "x_sg z_sg z_s2 z_tq";
export var SmallLarge = "x_sh z_sh z_s2 z_tm";
export var exceptionWeight = "x_sj z_tt";