// extracted by mini-css-extract-plugin
export var noMargin = "r_rk d_cc";
export var carouselCol = "r_rl d_cD";
export var carouselWrapper = "r_k6 d_k6 d_bz d_bD d_bP d_w d_H";
export var carouselImageWrapper = "r_k7 d_k7 d_H d_w";
export var carouselContainer = "r_rm d_cD";
export var carouselContainerFull = "r_rn d_dT";
export var carouselImg = "r_mR d_k8 d_w d_H d_bR";
export var imageTextWrapperLeft = "r_k1 d_k1 d_k0 d_0 d_bT";
export var imageTextWrapperCenter = "r_k2 d_k2 d_k0 d_0 d_bT";
export var imageTextWrapperRight = "r_k3 d_k3 d_k0 d_0 d_bT";
export var imageTextWrapperHidden = "r_kZ d_kZ";
export var imageAltSmall = "r_rp d_k4";
export var imageAltNormal = "r_rq d_k4";
export var imageAltLarge = "r_rr d_k4";
export var imageTextSmall = "r_rs d_k5";
export var imageTextNormal = "r_rt d_k5";
export var imageTextLarge = "r_rv d_k5";