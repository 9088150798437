// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "m_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "m_fR d_fR d_bz d_bJ";
export var menuDesign6 = "m_pC d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "m_pD d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "m_pF d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "m_pG d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "m_pH d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "m_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "m_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "m_pJ";
export var navbarItem = "m_nb d_bx";
export var navbarLogoItemWrapper = "m_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "m_pK d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "m_pL d_gd d_by d_Z d_bs";
export var burgerToggle = "m_pM d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "m_pN d_gd d_by d_Z d_bs";
export var burgerInput = "m_pP d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "m_pQ d_f3 d_w d_H";
export var burgerLine = "m_pR d_f1";
export var burgerMenuDesign6 = "m_pS d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "m_pT d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "m_pV d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "m_pW d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "m_pX d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "m_pY d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "m_pZ d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "m_p0 d_bC d_bP";
export var compact = "m_p1";
export var navDivided = "m_p2";
export var staticBurger = "m_p3";
export var menu = "m_p4";
export var navbarDividedLogo = "m_p5";
export var nav = "m_p6";
export var fixed = "m_p7";